<template>
  <div>
    
    <base-table ref="tableRef"
                rowKey="outlets_city_adcode"
                :columnsData="columns"
                :tableData="tableData">
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold">城市市场配置</h4>
          <!-- <a-button class="ml-3"
                    type="primary"
                    @click="handlerEdit"
                    html-type="submit">编辑</a-button> -->
        </div>
      </template>

      <template #only_supported_delivery_cake="{text}">
        <template>{{text == 2 ? "是" : "" }}</template>
      </template>
      <template #other_goods_scheduled_day="{text}">
        <template v-if="text == 1">当天</template>
        <template v-else-if="text">{{text}} 天</template>
        <template v-else>默认</template>
      </template>
      <template #cake_goods_scheduled_day="{text}">
        <template v-if="text == 1">当天</template>
        <template v-else-if="text">{{text}} 天</template>
        <template v-else>默认</template>
      </template>
      <template #pickup_duration="{text}">
        <template v-if="text">{{text}} 分钟</template>
        <template v-else>默认</template>
      </template>
      <template #pickup_delta="{text}">
        <template v-if="text">{{text}} 分钟</template>
        <template v-else>默认</template>
      </template>
      <template #delivery_duration="{text}">
        <template v-if="text">{{text}} 分钟</template>
        <template v-else>默认</template>
      </template>
      <template #delivery_delta="{text}">
        <template v-if="text">{{text}} 分钟</template>
        <template v-else>默认</template>
      </template>
      <template #drink_suborder_auto_finish="{text}">
        <template v-if="text==2">2小时自动取货</template>
        <template v-else>——</template>
      </template>
      <template #operation="{record}">
        <a-button type="link" class="p-0"
                  @click="handlerEdit(record)">编辑</a-button>
      </template>
    </base-table>

    <add-modal v-if="isEidtShow"
               :show.sync="isEidtShow"
               :adcode="activeAdcode"
               :cityName="activeCityName"/>
  </div>
</template>

<script>
import { 
  getConfigList,
} from '@/api/regional/config.js'
import addModal from './components/add-modal.vue'

export default {
  components: { addModal },
  data () {
    return {
      columns: [
        {
          title: "城市市场",
          dataIndex: "outlets_city_name",
          align: "center",
          // width: 70,
        },
        {
          title: "只配送纯蛋糕",
          dataIndex: "only_supported_delivery_cake",
          slots: { customRender: "only_supported_delivery_cake" },
          align: "center",
          // width: 90,
        },
        {
          title: "非蛋糕提前预订天数",
          dataIndex: "other_goods_scheduled_day",
          slots: { customRender: "other_goods_scheduled_day" },
          align: "center",
          // width: 90,
        },
        {
          title: "蛋糕提前预订天数",
          dataIndex: "cake_goods_scheduled_day",
          slots: { customRender: "cake_goods_scheduled_day" },
          align: "center",
        },
        // {
        //   title: "自提时长",
        //   dataIndex: "pickup_duration",
        //   slots: { customRender: "pickup_duration" },
        //   align: "center",
        // },
        // {
        //   title: "自提间隔",
        //   dataIndex: "pickup_delta",
        //   slots: { customRender: "pickup_delta" },
        //   align: "center",
        // },
        {
          title: "配送时长",
          dataIndex: "delivery_duration",
          slots: { customRender: "delivery_duration" },
          align: "center",
        },
        // {
        //   title: "配送间隔",
        //   dataIndex: "delivery_delta",
        //   slots: { customRender: "delivery_duration" },
        //   align: "center",
        // },
        {
          title: "好茶自动取货",
          dataIndex: "drink_suborder_auto_finish",
          slots: { customRender: "drink_suborder_auto_finish" },
          align: "center",
        },
        {
          title: "操作",
          dataIndex: "operation",
          slots: { customRender: "operation" },
          align: "center",
          width: "140px",
        },
      ],

      activeAdcode: '',
      activeCityName: '',
      tableData: [],

      isEidtShow: false,
    }
  },
  mounted () {
    // this.initCityList()
    this.initData()
  },
  methods: {
    async initData () {
      const { data, code } = await getConfigList()
      if (code === 0) {
        this.tableData = data.list
      }
    },

    handlerEdit (row) {
      this.activeAdcode = row ? row.outlets_city_adcode : ''
      this.activeCityName = row ? row.outlets_city_name : ''
      this.isEidtShow = true
    },

    resetForm () {
      this.$refs.searchRef.resetFields()
      this.initData()
    },
  },
}
</script>

<style>
</style>
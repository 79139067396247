var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-table',{ref:"tableRef",attrs:{"rowKey":"outlets_city_adcode","columnsData":_vm.columns,"tableData":_vm.tableData},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('div',{staticClass:"flex justify-between"},[_c('h4',{staticClass:"text-lg font-bold"},[_vm._v("城市市场配置")])])]},proxy:true},{key:"only_supported_delivery_cake",fn:function(ref){
var text = ref.text;
return [[_vm._v(_vm._s(text == 2 ? "是" : ""))]]}},{key:"other_goods_scheduled_day",fn:function(ref){
var text = ref.text;
return [(text == 1)?[_vm._v("当天")]:(text)?[_vm._v(_vm._s(text)+" 天")]:[_vm._v("默认")]]}},{key:"cake_goods_scheduled_day",fn:function(ref){
var text = ref.text;
return [(text == 1)?[_vm._v("当天")]:(text)?[_vm._v(_vm._s(text)+" 天")]:[_vm._v("默认")]]}},{key:"pickup_duration",fn:function(ref){
var text = ref.text;
return [(text)?[_vm._v(_vm._s(text)+" 分钟")]:[_vm._v("默认")]]}},{key:"pickup_delta",fn:function(ref){
var text = ref.text;
return [(text)?[_vm._v(_vm._s(text)+" 分钟")]:[_vm._v("默认")]]}},{key:"delivery_duration",fn:function(ref){
var text = ref.text;
return [(text)?[_vm._v(_vm._s(text)+" 分钟")]:[_vm._v("默认")]]}},{key:"delivery_delta",fn:function(ref){
var text = ref.text;
return [(text)?[_vm._v(_vm._s(text)+" 分钟")]:[_vm._v("默认")]]}},{key:"drink_suborder_auto_finish",fn:function(ref){
var text = ref.text;
return [(text==2)?[_vm._v("2小时自动取货")]:[_vm._v("——")]]}},{key:"operation",fn:function(ref){
var record = ref.record;
return [_c('a-button',{staticClass:"p-0",attrs:{"type":"link"},on:{"click":function($event){return _vm.handlerEdit(record)}}},[_vm._v("编辑")])]}}])}),(_vm.isEidtShow)?_c('add-modal',{attrs:{"show":_vm.isEidtShow,"adcode":_vm.activeAdcode,"cityName":_vm.activeCityName},on:{"update:show":function($event){_vm.isEidtShow=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
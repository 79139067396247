<template>
  <div>
    <!-- 新增 编辑modal -->
    <a-modal :visible="show"
             width="600px"
             :title="cityName"
             @ok="handlerSave"
             @cancel="$emit('update:show', false)">
      
      <a-form-model v-if="outlets_city_adcode" ref="addForm"
                    :labelCol="{ span: 7 }"
                    :wrapperCol="{ span: 17 }"
                    :model="formData">
        <a-form-model-item class="mb-2" prop="only_supported_delivery_cake" label="配送设置">
          <div>
            <a-radio-group name="radioGroup" v-model="formData.only_supported_delivery_cake">
                <a-radio value="1">含蛋糕就可以配送</a-radio>
                <a-radio value="2">只配送纯蛋糕订单</a-radio>
            </a-radio-group>
          </div>
        </a-form-model-item>
        <a-divider />

        <a-form-model-item class="mb-2" prop="other_goods_scheduled_day" label="非蛋糕预订天数">
          <a-select style="width:300px;" placeholder="请选择天数"
                v-model="formData.other_goods_scheduled_day">
            <a-select-option value="0">默认（当天）</a-select-option>
            <a-select-option value="1">当天</a-select-option>
            <a-select-option value="3">3天</a-select-option>
            <a-select-option value="7">7天</a-select-option>
            <a-select-option value="30">30天</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item class="mb-2" prop="cake_goods_scheduled_day" label="蛋糕预订天数">
          <a-select style="width:300px;" placeholder="请选择天数"
                v-model="formData.cake_goods_scheduled_day">
            <a-select-option value="0">默认（7天）</a-select-option>
            <a-select-option value="1">当天</a-select-option>
            <a-select-option value="3">3天</a-select-option>
            <a-select-option value="7">7天</a-select-option>
            <a-select-option value="30">30天</a-select-option>
            <a-select-option value="60">60天</a-select-option>
          </a-select>
        </a-form-model-item>

        <!-- <a-form-model-item class="mb-2" prop="pickup_duration" label="自提时长">
          <a-select style="width:300px;" placeholder="请选择时长"
                v-model="formData.pickup_duration">
            <a-select-option value="0">默认（30分钟）</a-select-option>
            <a-select-option value="15">15分钟</a-select-option>
            <a-select-option value="30">30分钟</a-select-option>
            <a-select-option value="60">60分钟</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item class="mb-2" prop="pickup_delta" label="自提间隔">
          <a-select style="width:300px;" placeholder="请选择时长"
                v-model="formData.pickup_delta">
            <a-select-option value="0">默认（30分钟）</a-select-option>
            <a-select-option value="15">15分钟</a-select-option>
            <a-select-option value="30">30分钟</a-select-option>
            <a-select-option value="60">60分钟</a-select-option>
          </a-select>
        </a-form-model-item> -->
        <a-form-model-item class="mb-2" prop="delivery_duration" label="配送时长">
          <a-select style="width:300px;" placeholder="请选择时长"
                v-model="formData.delivery_duration">
            <a-select-option value="0">默认（30分钟）</a-select-option>
            <a-select-option value="30">30分钟</a-select-option>
            <a-select-option value="60">60分钟</a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- <a-form-model-item class="mb-2" prop="delivery_delta" label="配送间隔">
          <a-select style="width:300px;" placeholder="请选择时长"
                v-model="formData.delivery_delta">
            <a-select-option value="0">默认（30分钟）</a-select-option>
            <a-select-option value="15">15分钟</a-select-option>
            <a-select-option value="30">30分钟</a-select-option>
            <a-select-option value="60">60分钟</a-select-option>
          </a-select>
        </a-form-model-item> -->
        <a-form-model-item class="mb-6" prop="drink_suborder_auto_finish" label="好茶自动取货">
          <a-checkbox v-model="isDrinkAutoPick">
            制作完成2小时后 自动取货
            <span style="color:#999">（即使顾客没取餐）</span>
          </a-checkbox>
          <div class="ml-5" style="line-height:20px; color:#999">（设置后，对新制作完成的订单才会生效）</div>
        </a-form-model-item>
        
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import {
  getConfigDetail,
  updateConfig
} from "@/api/regional/config.js"

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    adcode: {
      type: [String, Number],
      default: ''
    },
    cityName: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      outlets_city_adcode: "",

      formData: {
        outlets_city_adcode: "",
        only_supported_delivery_cake: "1",
        other_goods_scheduled_day: "0",
        cake_goods_scheduled_day: "0",
        // pickup_duration: "0",
        // pickup_delta: "0",
        delivery_duration: "0",
        // delivery_delta: "0",
        drink_suborder_auto_finish: 1,
      },
      isDrinkAutoPick: false,
    }
  },

  async mounted () {
    this.outlets_city_adcode = String(this.adcode || "")

    if(this.outlets_city_adcode){
      this.initData()
    }
  },
  methods: {
    async initData () {
      const { data } = await getConfigDetail({ 
        outlets_city_adcode: this.outlets_city_adcode 
      })
      this.formData = {
        outlets_city_adcode: data.outlets_city_adcode,
        only_supported_delivery_cake: String(data.only_supported_delivery_cake),
        other_goods_scheduled_day:    String(data.other_goods_scheduled_day),
        cake_goods_scheduled_day:     String(data.cake_goods_scheduled_day),
        // pickup_duration:    String(data.pickup_duration),
        // pickup_delta:       String(data.pickup_delta),
        delivery_duration:  String(data.delivery_duration),
        // delivery_delta:     String(data.delivery_delta),
        drink_suborder_auto_finish: data.drink_suborder_auto_finish
      }
      this.isDrinkAutoPick = data.drink_suborder_auto_finish==2
    },

    handlerSave () {
      if(this.outlets_city_adcode){
        this.formData.drink_suborder_auto_finish = this.isDrinkAutoPick?2:1
        updateConfig(this.formData).then((res) => {
          if (res.code === 0) {
            this.$message.success("已保存")
            this.$emit('update:show', false)
            this.$parent.initData()
          }
        })
      }
    },

  },

}
</script>

<style>
</style>
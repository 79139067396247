import ajax from '@/utils/ajax.js'

/**
 * 城市配置: 列表
 * @param {*} params 
 * @returns 
 */
export function getConfigList(params) {
  return ajax.post('/FinancialOutletsCityConfig/getList',params)
}

/**
 * 城市配置: 详情
 * @param {*} params 
 * @returns 
 */
export function getConfigDetail(params) {
  return ajax.post('/FinancialOutletsCityConfig/detail',params)
}

/**
 * 城市配置: 添加
 * @param {*} params 
 * @returns 
 */
export function addConfig(params) {
  return ajax.post('/FinancialOutletsCityConfig/add',params)
}

/**
 * 城市配置: 修改
 * @param {*} params 
 * @returns 
 */
export function updateConfig(params) {
  return ajax.post('/FinancialOutletsCityConfig/update',params)
}

